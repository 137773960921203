<template>
<div class="grid-page-wrapper">
    <h1 class="display-4">Users</h1>
    <hr>

    <ModalFormUser 
        ref="formCreate"
        idModal="modal-user-create" 
        title="Create user" 
        submitButtonText="Add user"
        :validations="formValidations"
        @submit="createItem"/>

    <ModalFormUser 
        ref="formEdit"
        idModal="modal-user-edit"
        :title="'Edit user: ' + editData.username"
        submitButtonText="Edit user"
        :validations="formValidations"
        :formData="editData"
        @submit="editItem"/>

    <div class="row mb-2">
        <div class="col-12">
            <button class="btn btn-success"
                    data-bs-toggle="modal" 
                    data-bs-target="#modal-user-create"
             ><i class="fa-solid fa-plus"></i> Add</button>
            <button class="btn btn-secondary dropdown-toggle mx-2"                     
                    id="btn-dropdown-export-table"
                    data-bs-toggle="dropdown">
                Export
            </button>
            <ul class="dropdown-menu" aria-labelledby="btn-dropdown-export-table">
                <li><button class="dropdown-item" @click="onBtExport()"><i class="fa-solid fa-file-csv"></i> CSV</button></li>
            </ul>
        </div>
    </div>
    <div class="grid-wrapper">
        <ag-grid-vue
            class="backoffice-grid ag-theme-alpine"
            :columnDefs="columnDefs"
            :defaultColDef="defaultColDef"
            @grid-ready="onGridReady"
            :frameworkComponents="frameworkComponents"
            :paginationAutoPageSize="true"
            :pagination="true"
            :rowData="rowData">
        </ag-grid-vue>
    </div>
</div>

</template>


<script>
import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-alpine.css"
import { AgGridVue } from "ag-grid-vue3"
import { Modal } from 'bootstrap'
import Swal from 'sweetalert2'

import TableButtonEdit from "../../shared/components/TableButtonEdit"
import TableButtonDelete from "../../shared/components/TableButtonDelete"
import ModalFormUser from '../components/ModalFormUser'
import { getUsers, insertUser, updateUser, deleteUser } from '../helpers/endpoints';
import { defaultButtonColumnDef } from '../../shared/helpers/GridHelpers'

export default {
    components: {
        AgGridVue,
        ModalFormUser
    },
    data() {
        return {
            columnDefs: [
                { headerName: "Username", field: "username", filter: 'agTextColumnFilter' },
                // { headerName: "e-mail", field: "email", filter: 'agTextColumnFilter' },
                { headerName: "First name", field: "first_name", filter: 'agTextColumnFilter' },
                { headerName: "Last name", field: "last_name", filter: 'agTextColumnFilter' },
                { headerName: "Role", field: "role", filter: 'agTextColumnFilter', cellRenderer: (params) => {
                    switch(params.value) {
                        case 1: return "Operator"
                        case 2: return "Manager"
                    }
                } },
                {
                    field: 'pk',
                    cellRenderer: 'btnEditRenderer',
                    cellRendererParams: {
                        action: this.openEditModal,
                        disabled: (data) => data.is_staff
                    },
                    ...defaultButtonColumnDef
                },
                {
                    headerName: '',
                    field: 'pk',
                    cellRenderer: 'btnDeleteRenderer',
                    cellRendererParams: {
                        action: this.removeItem,
                        disabled: (data) => data.is_staff
                    },
                    ...defaultButtonColumnDef
                }
            ],
            defaultColDef: {
                flex: 1,
                minWidth: 125,
                resizable: true,
                floatingFilter: true,
                sortable:true
            },
            frameworkComponents: {
                btnEditRenderer: TableButtonEdit,
                btnDeleteRenderer: TableButtonDelete
            },
            rowData: [],
            editData: {},
            formValidations: {}
        }
    },

    computed: {
    },

    methods: {

        renderTable() {
            getUsers().then((response) => {
                this.rowData = response;
            })
        },

        openEditModal(formData) {
            console.log(formData);
            this.editData = formData
            let modalEdit = new Modal(document.getElementById('modal-user-edit'))
            modalEdit.show()
        },

        createItem($event, formData) {
            console.log("Creating user");
            console.log($event);
            console.log(formData);
            insertUser(formData).then((response) => {
                console.log(response);
                this.renderTable()

                let modalCreate = Modal.getInstance(document.getElementById('modal-user-create'))
                modalCreate.hide()
                
                Swal.fire(
                    'Good job!',
                    'User added with success',
                    'success'
                )
            }).catch((error) => {
                const { data } = error.response
                this.formValidations = data
            })
        },

        editItem($event, formData) {
            console.log("Editing user");
            console.log($event);
            console.log(formData);
            updateUser(this.editData.pk, formData).then((response) => {
                console.log(response);
                this.renderTable()

                let modalEdit = Modal.getInstance(document.getElementById('modal-user-edit'))
                modalEdit.hide()
                
                Swal.fire(
                    'Good job!',
                    'User edited with success',
                    'success'
                )
                
            }).catch((error) => {
                const { data } = error.response
                this.formValidations = data
            })

        },

        removeItem({pk: id}) {
            console.log(id);
            console.log(`Deleting user ${id}`);
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel!',
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {

                    deleteUser(id)
                        .then((response)=> {
                            console.log(response)
                            this.renderTable()
                            swalWithBootstrapButtons.fire(
                                'Deleted!',
                                'User has been deleted.',
                                'success'
                            )
                        })
                    
                } else if (
                    /* Read more about handling dismissals below */
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                    swalWithBootstrapButtons.fire(
                        'Cancelled',
                        'Your user is safe :)',
                        'error'
                    )
                }
            })
        },

        onBtExport() {
            console.log("Export to CSV")
            this.gridApi.exportDataAsCsv({
                columnSeparator: ','
            });
        },
        onGridReady(params) {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;
 
            this.renderTable()
        }   
        
    },

    watch: {
        selectSelection(newSelection, oldSelection) {
            if (newSelection != oldSelection) {
                this.renderTable(newSelection.code)
            }
        },

    },
}
</script>
<style lang="scss" scoped>
    // @import "vue-tabulator/dist/scss/bootstrap/tabulator_bootstrap4.scss";
</style>