<template>
<div class="modal fade" :id="idModal" :ref="idModal" tabindex="-1" aria-labelledby="modal-product" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ title }}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <form>
                <div class="mb-3">
                    <label for="input-reference" class="form-label">Username</label>
                    <BasicInput 
                            v-model="username"
                            :invalidFeedbackText="valErrors.username"
                            textHelp="User unique name."/>
                </div>
                <div class="mb-3">
                    <label for="input-reference" class="form-label">First name</label>
                    <BasicInput 
                            v-model="first_name"
                            :invalidFeedbackText="valErrors.first_name"
                            textHelp="User real name."/>
                </div>
                <div class="mb-3">
                    <label for="input-reference" class="form-label">Last name</label>
                    <BasicInput 
                            v-model="last_name"
                            :invalidFeedbackText="valErrors.last_name"
                            textHelp="User surnames."/>
                </div>
                <div class="mb-3">
                    <label for="input-role" class="form-label">Role</label>
                    <select id="input-role" v-model="role" class="form-select" aria-label="Select role">
                        <option value="1">Operator</option>
                        <option value="2">Manager</option>
                    </select>
                </div>
                <div class="mb-3">
                    <label for="input-role" class="form-label">Password</label>
                    <BasicInput 
                            inputType="password"
                            v-model="password"
                            :invalidFeedbackText="valErrors.password" />
                </div>
                <div class="mb-3">
                    <label for="input-role" class="form-label">Password (confirm)</label>
                    <input type="password" class="form-control" 
                           v-model="password2"
                           ref="inputPassword2" aria-describedby="referenceHelp">
                    <div class="valid-feedback">Password correcto. </div>
                    <div class="invalid-feedback">Los passwords no coinciden. </div>
                </div>
            </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary" :class="{disabled: password && (password != password2) }" @click="submit">{{ submitButtonText }}</button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import BasicInput from '../../shared/components/BasicInput';

export default {
    components: {
        BasicInput
    },
    props: {
        title: {
            required: true,
            type: String
        },
        idModal: {
            required: true,
            type: String
        },
        submitButtonText: {
            required: true,
            type: String
        },
        formData: {
            type: Object
        },
        validations: {
            type: Object
        }
    },
    
    data() {
        return {
            username: '',
            first_name: '',
            last_name: '',
            password: undefined,
            password2: undefined,
            role: 1,
            valErrors: { 
                username: '',
                first_name: '',
                last_name: '',
                password: '',
                role: '',
            }
        }
    },

    methods: {
        cleanForm() {
            this.cleanValidations()
            this.username = ''
            this.first_name = ''
            this.last_name = ''
            this.password = undefined
            this.password2 = undefined
            this.role = 1
        },

        cleanValidations() {
            for (const field in this.valErrors) {
                this.valErrors[field] = ''
            }
        },

        submit($event) {
            if (this.password && (this.password != this.password2)) {
                console.log("Password confirmation fail")
                return
            }
            const formData = { 
                username: this.username, 
                first_name: this.first_name, 
                last_name: this.last_name, 
                role: this.role,
                password: this.password
            }
            this.$emit('submit', $event, formData)
        }
    },

    watch: {
        formData(newFormData) {
            console.log(newFormData);
            if (newFormData) {
                this.username = newFormData.username
                this.first_name = newFormData.first_name
                this.last_name = newFormData.last_name
                this.role = newFormData.role
            }
        },
        
        password2(newPassword2) {
            if (!this.password) {
                this.$refs.inputPassword2.classList.remove("is-valid");
                this.$refs.inputPassword2.classList.add('is-valid')
                return
            }
            console.log(this.$refs.inputPassword2);
            if (newPassword2 === this.password){
                this.$refs.inputPassword2.classList.remove("is-invalid");
                this.$refs.inputPassword2.classList.add('is-valid')
            } else {
                this.$refs.inputPassword2.classList.remove("is-valid");
                this.$refs.inputPassword2.classList.add('is-invalid')
            }
        },

        validations(newValidations) {
            this.cleanValidations()
            for (const field in newValidations) {
                console.log(`Error in field ${field}: ${newValidations[field]}`);
                this.valErrors[field] = newValidations[field][0]
            }           
        }
    },

    mounted() {
        let $modal = document.getElementById(this.idModal)
        let self = this
        $modal.addEventListener('hidden.bs.modal', function () {
            self.cleanForm()
        })
    }

}
</script>

<style>

</style>